import React from "react"
import { Link } from "gatsby"

function NewCards() {
  return (
    <div className="page__newcards newcards">
      <div className="newcards__container">
        <div className="newcards__body body-newcards">
          <div className="body-newcards__titles">
            <h2 className="body-newcards__title">New Cards.</h2>
            <h2 className="body-newcards__subtitle">Fresh Start.</h2>
            <div className="body-newcards__text">
              <p>
                We did some searching to bring you even better features than
                before. Compare cards and choose the one that works for you, or
                get both!
              </p>
            </div>
          </div>
          <div className="body-newcards__grid-block block-grid">
            <div className="block-grid__get get">
              <div className="get__background">
                <picture>
                  <source
                    srcSet="/main/grid-block/get/get-image.webp"
                    type="image/webp"
                  />
                  <img
                    className="background-get"
                    src="/main/grid-block/get/get-image.png?_v=1638553701131"
                    alt="get-bg"
                  />
                </picture>
              </div>
              <div className="get__content">
                <div className="content-get">
                  <div className="content-get__titles titles-get">
                    <h3 className="titles-get__title common-title">
                      Get paid and pay
                    </h3>
                    <div className="titles-get__text common-text">
                      <p>Open a free account in minutes from your phone</p>
                      <p>Receive your salary and manage your money</p>
                    </div>
                    <a
                      data-da=".content-get,767.98,last"
                      href="https://solcard.onelink.me/xMJ9/53a121ee"
                      className="titles-get__button arrow-button"
                    >
                      Open for free<span></span>
                    </a>
                  </div>
                  <div
                    data-da=".titles-get,767.98,last"
                    className="content-get__icons"
                  >
                    <div className="icon-get-item">
                      <div className="icon-get-item__icon">
                        <img
                          className="get-icon-image"
                          src="/main/grid-block/get/get-icon-01.svg?_v=1638553701131"
                          alt="get-icon"
                        />
                      </div>
                      <div className="icon-get-item__text">
                        <p>Make online payments</p>
                      </div>
                    </div>
                    <div className="icon-get-item">
                      <div className="icon-get-item__icon">
                        <img
                          className="get-icon-image"
                          src="/main/grid-block/get/get-icon-02.svg?_v=1638553701131"
                          alt="get-icon"
                        />
                      </div>
                      <div className="icon-get-item__text">
                        <p>Shop safely online</p>
                      </div>
                    </div>
                    <div className="icon-get-item">
                      <div className="icon-get-item__icon">
                        <img
                          className="get-icon-image"
                          src="/main/grid-block/get/get-icon-03.svg?_v=1638553701131"
                          alt="get-icon"
                        />
                      </div>
                      <div className="icon-get-item__text">
                        <p>get your card delivered</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="block-grid__easy easy">
              <div className="easy__content content-easy">
                <div className="content-easy__instructions instructions-easy">
                  <h3 className="instructions-easy__title common-title">
                    Easy to start
                  </h3>
                  <ol className="instructions-easy__list">
                    <li className="instructions-easy__item common-text common-text_white">
                      Download the app
                    </li>
                    <li className="instructions-easy__item common-text common-text_white">
                      Submit your passport or SA ID 
                    </li>
                    <li className="instructions-easy__item common-text common-text_white">
                      Take a Selfie
                    </li>
                  </ol>
                </div>
                <a href="https://solcard.onelink.me/xMJ9/53a121ee" className="content-easy__button arrow-button">
                  Download now<span></span>
                </a>
              </div>
            </div>
            <div className="block-grid__debitcard debitcard">
              <div className="debitcard__background _ibg">
                <picture>
                  <source
                    srcSet="/main/grid-block/debitcard/debitcard-background.webp"
                    type="image/webp"
                  />
                  <img
                    className="background-debitcard"
                    src="/main/grid-block/debitcard/debitcard-background.jpg?_v=1638553701131"
                    alt="debitcard-background"
                  />
                </picture>
              </div>
              <div className="debitcard__content content-debitcard">
                <div className="content-debitcard__info info-debitcard">
                  <h3 className="info-debitcard__title common-title">
                    Debit Card
                  </h3>
                  <div className="info-debitcard__text common-text">
                    <p>
                      Get your debit or virtual card to pay everywhere in SA and
                      withdraw cash.
                    </p>
                  </div>
                </div>
                <div className="content-debitcard__icons">
                  <div className="icon-debitcard-item">
                    <div className="icon-debitcard-item__icon">
                      <img
                        className="debitcard-icon-image"
                        src="/main/grid-block/debitcard/debitcard-icon-01.svg?_v=1638553701131"
                        alt="debitcard-icon"
                      />
                    </div>
                    <div className="icon-debitcard-item__text">
                      <p>Freeze card</p>
                    </div>
                  </div>
                  <div className="icon-debitcard-item">
                    <div className="icon-debitcard-item__icon">
                      <img
                        className="debitcard-icon-image"
                        src="/main/grid-block/debitcard/debitcard-icon-02.svg?_v=1638553701131"
                        alt="debitcard-icon"
                      />
                    </div>
                    <div className="icon-debitcard-item__text">
                      <p>PIN & Security</p>
                    </div>
                  </div>
                </div>
                <a href="https://solcard.onelink.me/xMJ9/53a121ee" className="content-debitcard__button arrow-button">
                  Apply now<span></span>
                </a>
              </div>
            </div>
            <div className="block-grid__prepaid prepaid">
              <div className="prepaid__text-block text-block-prepaid">
                <h3 className="text-block-prepaid__title common-title">
                  Prepaid and transactional services.
                </h3>
                <div className="text-block-prepaid__text common-text common-text_white">
                  <p>Topup Airtime, Electricity, DSTV with NO fees.</p>
                </div>
                <div className="text-block-prepaid__text common-text common-text_white">
                  <p>Transact easily via eWallet, EFT, RTC through one app.</p>
                </div>
                <div className="text-block-prepaid__text common-text common-text_white">
                  <p>Make internal free transfers to any SOLmate customers.</p>
                </div>
              </div>
              <div className="prepaid__icons-block">
                <div className="icons-prepaid">
                  <div className="icons-prepaid__icon">
                    <img
                      className="prepaid-icon-image"
                      src="/main/grid-block/prepaid/prepaid-icon-01.svg?_v=1638553701131"
                      alt="prepaid-icon-image"
                    />
                  </div>
                  <div className="icons-prepaid__icon">
                    <img
                      className="prepaid-icon-image"
                      src="/main/grid-block/prepaid/prepaid-icon-02.svg?_v=1638553701131"
                      alt="prepaid-icon-image"
                    />
                  </div>
                  <div className="icons-prepaid__icon">
                    <img
                      className="prepaid-icon-image"
                      src="/main/grid-block/prepaid/prepaid-icon-03.svg?_v=1638553701131"
                      alt="prepaid-icon-image"
                    />
                  </div>
                  <div className="icons-prepaid__icon">
                    <img
                      className="prepaid-icon-image"
                      src="/main/grid-block/prepaid/prepaid-icon-04.svg?_v=1638553701131"
                      alt="prepaid-icon-image"
                    />
                  </div>
                  <div className="icons-prepaid__icon">
                    <img
                      className="prepaid-icon-image"
                      src="/main/grid-block/prepaid/prepaid-icon-05.svg?_v=1638553701131"
                      alt="prepaid-icon-image"
                    />
                  </div>
                  <div className="icons-prepaid__icon">
                    <img
                      className="prepaid-icon-image"
                      src="/main/grid-block/prepaid/prepaid-icon-06.svg?_v=1638553701131"
                      alt="prepaid-icon-image"
                    />
                  </div>
                  <div className="icons-prepaid__icon">
                    <img
                      className="prepaid-icon-image"
                      src="/main/grid-block/prepaid/prepaid-icon-07.svg?_v=1638553701131"
                      alt="prepaid-icon-image"
                    />
                  </div>
                  <div className="icons-prepaid__icon">
                    <img
                      className="prepaid-icon-image"
                      src="/main/grid-block/prepaid/prepaid-icon-08.svg?_v=1638553701131"
                      alt="prepaid-icon-image"
                    />
                  </div>
                  <div className="icons-prepaid__icon">
                    <img
                      className="prepaid-icon-image"
                      src="/main/grid-block/prepaid/prepaid-icon-09.svg?_v=1638553701131"
                      alt="prepaid-icon-image"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="block-grid__special special">
              <div className="special__text-block text-block-special">
                <h3 className="text-block-special__title common-title">
                  Special deals
                </h3>
                <div className="text-block-special__text common-text">
                  <p>
                    Save time & money searching for the best deals from SA’s favorite retailers
                  </p>
                </div>
              </div>
              <a href="https://solcard.onelink.me/xMJ9/53a121ee" className="special__button arrow-button">
                Try now<span></span>
              </a>
            </div>
            <div className="block-grid__betting betting">
              <div className="betting__text-block text-block-betting">
                <h3 className="text-block-betting__title common-title">
                  Betting & Lottery
                </h3>
                <div className="text-block-betting__text common-text common-text_white">
                  <p>
                    No fees to buy betting and lottery vouchers. Instant and
                    easy.
                  </p>
                </div>
              </div>
              <a href="https://solcard.onelink.me/xMJ9/53a121ee" className="betting__button arrow-button">
                Try now<span></span>
              </a>
            </div>
            <div className="block-grid__refferal refferal">
              <div className="refferal__text-block text-block-refferal">
                <h3 className="text-block-refferal__title common-title">
                  Refferal system
                </h3>
                <div className="text-block-refferal__text common-text common-text_white">
                  <p>
                    Invite friends and receive rewards when they get a SOLmate debit card.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="body-newcards__end-title title-end">
            <h2 className="title-end__title">
              I want to know more about{" "}
              <Link to="/pricing" className="price-link">
                the price plan
              </Link>
            </h2>
            <Link
              to="pricing"
              className="title-end__button pink-button pink-button_end"
            >
              Your SOLmate benefits
            </Link>
          </div>
        </div>
      </div>
    </div>
  )
}

export default NewCards
