import React, { useEffect } from "react"

import "../css/style.css"
import Layer from "../components/Layer"
import Hero from "../components/blocks/Hero"
import NewCards from "../components/blocks/NewCards"
import Reviews from "../components/blocks/Reviews"
import CareerSteps from "../components/blocks/CareerSteps"
import da from "../js/dynamic_adapt"
import { spollers } from "../js/functions"
import Seo from "../components/Seo"

export default function Home() {
	useEffect(() => {
		da.init()
		spollers()
	}, [])
	return (
		<>
			<Seo title="SOLmate"/>
			<Layer>
				<main className="page">
					<Hero />
					<NewCards />
					<Reviews />
					<CareerSteps />
				</main>
			</Layer>
		</>
	)
}
