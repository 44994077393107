import React from "react"

function Reviews() {
  return (
    <div className="page__what what">
      <div className="what__container">
        <div className="what__body body-what">
          <h2 className="body-what__title">
            What customers say <span>about SOLmate</span>
          </h2>
          <div className="body-what__comments comments-cards">
            <div className="comments-cards__card">
              <div className="comment-content">
                <div className="comment-content__text">
                  <p>
                    SOLmate has kept my money safe and secure. The free digital
                    wallet made it easy to get paid and make some online payment
                    – it simply works for me!
                  </p>
                </div>
                <div className="comment-content__name">
                  <p>John Dupre</p>
                </div>
              </div>
            </div>
            <div className="comments-cards__card">
              <div className="comment-content">
                <div className="comment-content__text">
                  <p>
                    Just received my card today wow! Sol looks like a real deal.
                    Can’t wait to receive my salary and enjoy the services.
                  </p>
                </div>
                <div className="comment-content__name">
                  <p>Christopher Varaigwai</p>
                </div>
              </div>
            </div>
            <div className="comments-cards__card">
              <div className="comment-content">
                <div className="comment-content__text">
                  <p>I’m using it and I love this service!</p>
                </div>
                <div className="comment-content__name">
                  <p>Simoa Chissano</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Reviews
