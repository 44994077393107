import React from "react"

function CareerSteps() {
  return (
    <div className="page__build build">
      <div className="build__container">
        <div className="build__body body-build">
          <h2 className="body-build__title">
            Build your own career with SOLmate
          </h2>
          <div className="body-build__cards steps-cards">
            <div className="steps-cards__card">
              <div className="step-content">
                <div className="step-content__icon">
                  <img
                    className="step-card-icon"
                    src="/main/build/build-step-icon-01.svg?_v=1638553701131"
                    alt="step-card-icon"
                  />
                </div>
                <h3 className="step-content__title">Step 1</h3>
                <div className="step-content__text">
                  <p>Become a SOLmate agent in a day</p>
                </div>
              </div>
            </div>
            <div className="steps-cards__card">
              <div className="step-content step-content_invert-decor">
                <div className="step-content__icon">
                  <img
                    className="step-card-icon"
                    src="/main/build/build-step-icon-02.svg?_v=1638553701131"
                    alt="step-card-icon"
                  />
                </div>
                <h3 className="step-content__title">Step 2</h3>
                <div className="step-content__text">
                  <p>Get trained by the best SOLmate super agents</p>
                </div>
              </div>
            </div>
            <div className="steps-cards__card">
              <div className="step-content">
                <div className="step-content__icon">
                  <img
                    className="step-card-icon"
                    src="/main/build/build-step-icon-03.svg?_v=1638553701131"
                    alt="step-card-icon"
                  />
                </div>
                <h3 className="step-content__title">Step 3</h3>
                <div className="step-content__text">
                  <p>
                    Earn cash for every SOLmate debit and virtual card you
                    activate
                  </p>
                </div>
              </div>
            </div>
            <div className="steps-cards__card">
              <div className="step-content step-content_invert-decor">
                <div className="step-content__icon">
                  <img
                    className="step-card-icon"
                    src="/main/build/build-step-icon-02.svg?_v=1638553701131"
                    alt="step-card-icon"
                  />
                </div>
                <h3 className="step-content__title">Step 4</h3>
                <div className="step-content__text">
                  <p>Become a super-agent and build your own team</p>
                </div>
              </div>
            </div>
          </div>
          <a href="https://solmate.co.za/become-an-agent/" className="body-build__button pink-button">
            Apply
          </a>
        </div>
      </div>
    </div>
  )
}

export default CareerSteps
