import React from "react"

function Hero() {
  return (
    <div className="debit">
      <div className="debit__background _ibg">
        <picture>
          <source
            srcSet="/main/debit/debit-background.webp"
            type="image/webp"
          />
          <img
            className="background-debit-image"
            src="/main/debit/debit-background.jpg?_v=1638553701131"
            alt="background-image"
          />
        </picture>
      </div>
      <div className="debit__container">
        <div data-da=".page,767.98,1" className="debit__content content-debit">
          <h1 className="content-debit__title">SOLmate Debit Card</h1>
          <div className="content-debit__text">
            <p>
              More than just a card, get paid, pay online, swipe, withdraw,
              purchase prepaid services. Powered by Mastercard
            </p>
          </div>
          <a href="https://solcard.onelink.me/xMJ9/53a121ee" className="content-debit__button pink-button">
            Get Started
          </a>
        </div>
      </div>
    </div>
  )
}

export default Hero
